<template>
  <div>
    <main>
      <h1>「スマート食品規格書」利用規約</h1>
      <p>株式会社Modelor及び株式会社スマショク（以下「当社」といいます。）が提供する「スマート食品規格書」（以下「本サービス」といいます。）の利用に関して、契約者（第 2 条第 2 号に定義するものをいいます。以下同じ。）及び利用希望者（第 2 条第 ３ 号に定義するものをいいます。以下同じ。）に厳守していただかなければならない事項及び当社とユーザーの皆様との間の権利義務関係が定められております。<br>
        <br>
        「スマート食品規格書」をご利用になる際には、この「スマート食品規格書」利用規約（以下「本規約」といいます。）が適用されます。なお、本規約については、利用希望者が利用登録した時点で同意されたものとさせていただきますので、本サービスをご利用になる方は、本規約に同意する前に必ず全文をお読みくださいますようお願い申し上げます。
      </p>
      <section>
        <h2>第 1 条（適用）</h2>
        <ol class="dot">
          <li>本規約は、当社が契約者に提供・運営する本サービスについて、契約者又は利用希望者と当社の間の本サービス利用に関する基本的な事項を規定します。</li>
          <li>本規約は、本サービスの利用に関し、利用希望者又は契約者と当社に対して適用されます。</li>
          <li>当社が本サービス等上で随時掲載する本サービスに関するルール、諸規定等は本規約の一部を構成するものとします。</li>
          <li>なお、申込書等に基づく個別契約により修正された項目等がある場合には、個別契約の規定が優先して適用されます。</li>
        </ol>
      </section>
      <section>
        <h2>第 2 条（定義）</h2>
        <p>本規約において使用する以下の用語は、各々以下に定める意味を有するものとします。</p>
        <ol class="parenthesis">
          <li>「サービス利用契約」<br>
            本サービスを利用するに際し、当社と契約者の間に発生する本サービスの利用に関する契約関係をいい、本規約、当社ウェブサイト等上に掲載されるこれに関連する規約・通知等をその内容に含みます。
          </li>
          <li>「契約者」<br>
            本規約を承認の上、当社と「サービス利用契約」を締結した法人、団体又は個人をいいます。なお、「契約者」は、全てのユーザー（第 4 号に定義するものをいいます。）の集合体としての意味を包含します。
          </li>
          <li>「利用希望者」<br>
            当社と「サービス利用契約」を締結することを希望する法人、団体又は個人をいいます。
          </li>
          <li>「ユーザー」<br>
            契約者が本サービスの利用を認め、個別のユーザーアカウント（第 9 号に定義するものをいいます。）を付与された者をいいます。
          </li>
          <li>
            「登録情報」<br>
            利用希望者及び契約者がサービス利用契約締結前に本サービスに登録した当社が定める情報、本サービス利用中に当社が必要と判断して登録を求めた情報及びこれらの情報について契約者自身が追加、変更を行った場合の当該情報をいいます。
          </li>
          <li>
            「送信データ」<br>
            契約者が本サービスを利用して送信又は保存するコンテンツ（文字、数字、数式、画像その他のデータを含みますがこれらに限りません。）を意味します。
          </li>
          <li>
            「ユーザーID」<br>
            ユーザーパスワードと組み合わせて、契約者とその他の者（同一契約者によって指定されたユーザー同士も含みます。）とを識別するために用いられる符号をいいます。
          </li>
          <li>
            「ユーザーパスワード」<br>
            ユーザーIDと組み合わせて、契約者とその他の者（同一契約者によって指定されたユーザー同士も含みます。）とを識別するために用いられる符号をいいます。
          </li>
          <li>
            「ユーザーアカウント」<br>
            ユーザーID及びユーザーパスワードを総称したものをいいます。
          </li>
          <li>
            「事業グループ」<br>
            契約者によって本サービス上で作成されたグループであり、当該グループにおける送信データを、相互に利用・編集できるユーザーの集合体をいいます。
          </li>
          <li>
            「知的財産権」<br>
            著作権、特許権、実用新案権、商標権、意匠権その他の知的財産権（それらの権利を取得し、又はそれらの権利につき登録等を出願する権利を含みます。）をいいます。
          </li>
        </ol>
      </section>
      <section>
        <h2>第 3 条（本サービスの内容）</h2>
        <ol class="dot">
          <li>本サービスは、契約者が個人・団体の情報並びに食品に関連する情報を入力することにより、食品規格書に関する情報を取得できるサービスに関連又は付随するサービスをいいます。</li>
          <li>当社は、契約者が閲覧することができる情報を追加又は削除することがありますが、契約者は、これに異議を述べることができないものとします。</li>
        </ol>
      </section>
      <section>
        <h2>第 4 条（契約者の登録）</h2>
        <ol class="dot">
          <li>利用希望者は、本規約を遵守することに同意し、かつ登録情報を当社の定める方法で当社に提供することにより、当社に対し、本サービスの利用の登録を申請することができるものとします。</li>
          <li>当社は、当社の基準に従って、利用希望者の登録の可否を判断し、当社が登録を認めた利用希望者に限り、本サービスを提供するものとします。</li>
          <li>本規約に基づくサービス利用契約は、当社が契約者に対して、本サービスを利用するためのユーザーID及びユーザーパスワードを発行した時に、契約者と当社の間に成立し、これ以降、契約者は本サービスを当社の定める方法で利用することができるようになります。</li>
          <li>契約者は、登録情報の登録にあたっては、真実かつ正確な情報を送信しなければなりません。当社は、契約者自身が登録した登録情報を前提として、本サービスを提供いたします。登録情報の内容に虚偽、誤り又は記載漏れがあったことにより契約者に生じた損害について、当社は一切責任を負いません。次条により登録情報の変更が生じた場合も同様とし、当社は契約者による本サービス利用時点において本サービスに登録されている登録情報を前提として、本サービスを提供いたします。</li>
        </ol>
      </section>
      <section>
        <h2>第 5 条（契約者の登録情報の変更）</h2>
        <ol class="dot">
          <li>契約者は、登録情報に変更があった場合は、速やかに、当社所定の変更手続を行うものとします。</li>
          <li>契約者は、前項の変更を怠ったことにより当社からの通知が不到達となった場合、当該通知は通常到達すべき時に到達したとみなされることを予め異議無く承諾するものとします。</li>
          <li>契約者が第1項の変更を怠ったことにより生じた損害について、当社は一切責任を負わないものとします。</li>
        </ol>
      </section>
      <section>
        <h2>第 6 条（ユーザーID及びユーザーパスワードの管理）</h2>
        <ol class="dot">
          <li>契約者は、自己の責任において、当社から発行されたユーザーID及びユーザーパスワードを管理及び保管するものとし、これを第三者に利用させ、貸与・譲渡・名義変更・売買等をしてはならないものとします。当社は、当該ユーザーID及びユーザーパスワードの一致を確認した場合、当該ユーザーID及びユーザーパスワードを保有するものとして登録された契約者が本サービスを利用したものとみなします。</li>
          <li>ユーザーアカウントの管理不十分、使用上の過誤、及び第三者の使用等による損害の責任は契約者が負うものとし、当社は一切の責任を負いません。</li>
          <li>契約者は、ユーザーID若しくはユーザーパスワード又が盗まれたり、第三者に使用されていることが判明した場合には、直ちにその旨を当社に通知するとともに、当社からの指示に従うものとします。なお、この場合において、当社の指示に従った結果、契約者に損害が生じたとしても、当社はかかる損害を賠償する責任を一切負いません。</li>
        </ol>
      </section>
      <section>
        <h2>第 7 条（利用プラン）</h2>
        <ol class="dot">
          <li>本サービスでは、契約者が利用できるサービス内容等に応じた、複数の利用プランが存在します。契約者は、自己が利用する契約者又は事業グループ毎に利用プランを１つ選択いただきます。利用プランの詳細については。当社ウェブサイト等をご確認ください。</li>
          <li>契約者は、当社ウェブサイト等上で、契約者又は事業グループが利用する利用プランの変更を申し込むことができるものとします。この申込みを当社が承諾したときは、当該契約者又は事業グループについて、変更後の利用プランが適用されます。なお、当社が承諾した日（変更日）の属する月の利用料金は、当社が別途定める金額とします。</li>
          <li>利用プランの変更を行った場合、変更前のプランにおいて編集又は閲覧できた情報が編集又は閲覧できなくなることがあり、契約者は予めこれを承諾するものとします。</li>
          <li>当社は、各利用プランの内容を変更する場合があります。当社は、変更の内容、変更に伴う措置等を、当社ウェブサイト等への掲載その他当社が適当と判断する方法により契約者に通知します。</li>
        </ol>
      </section>
      <section>
        <h2>第 8 条（利用料金）</h2>
        <ol class="dot">
          <li>契約者は、当社に対し、前条で選択した利用プランに基づき、当社が別途定める金額及び方法に従い、サービス利用の対価（以下「利用料金」といいます。）を支払うものとします。利用料金の詳細については、当社ウェブサイト等をご確認ください。</li>
          <li>当社は、当社が必要と判断した場合、契約者へ事前に通知することにより、利用料金の改定をすることができるものとします。当社が利用料金を改定した時点以降契約者が利用を継続した場合、当該契約者は、改定後の利用料金を異議なく受け入れることを表明したものとみなします。ただし、当社は、当社の裁量で、既存の契約者に対し、旧利用料金の適用を認める移行期間を設けることができるものとします。</li>
          <li>契約者が利用料金の支払を遅滞した場合、契約者は、当社に対し、年14.6％の割合による遅延損害金を支払うものとします。</li>
          <li>本規約に基づくサービス利用契約のうち、年額プラン・年間契約は、契約者が有効期間終了日までに解約をしない限り、有効期間終了日の経過時に従前と同一内容にて自動更新するものとします。なお、年額プラン・年間契約を途中解約した場合でも、当該契約の残期間に対応する利用料金は発生するものとし、当社は日割計算等による精算及び返金は行いません。</li>
          <li>本規約に別途定める場合を除き、当社は、契約者から当社に対して支払われた利用料金の返金には一切応じないことにつき、契約者は予め承諾するものとします。</li>
        </ol>
      </section>
      <section>
        <h2>第 9 条（本サービスの利用）</h2>
        <ol class="dot">
          <li>契約者は、サービス利用契約の有効期間内において、日本国内での利用に限り、本規約の目的の範囲内でかつ本規約に違反しない範囲内で、当社の定める方法に従い、本サービスを利用することができるものとします。</li>
          <li>本サービスの提供を受けるために必要なコンピューター、ソフトウェアその他の機器、通信回線その他の通信環境等の準備及び維持は、契約者の費用と責任において行うものとします。</li>
          <li>契約者は、本サービスの利用開始に際し又は本サービスの利用中に、当社ウェブサイト等からのダウンロードその他の方法によりソフトウェア等を契約者のコンピューター等にインストールする場合、契約者が保有する情報の消滅若しくは改変又は機器の故障、損傷等が生じないよう十分な注意を払うものとし、当社は、かかる事象に基づき契約者に生じた損害について一切責任を負わないものとします。</li>
          <li>
            契約者が、本サービスを利用する場合にあっては、次に定める事項を遵守するものとします。
            <ol class="parenthesis">
              <li>当該サービスの利用に関して不正アクセス、情報流出・漏洩等又はそのおそれが生じたと判断した場合（当該サービスの利用のための認証方法に係るユーザーパスワード等を流出・漏洩した場合を含みますが、これに限りません。）、直ちに当社に対して連絡すること。</li>
              <li>当該サービスの利用に関して不正アクセス、情報流出・漏洩等の未然防止のために行うべきセキュリティ関連措置を行うこと。</li>
            </ol>
          </li>
        </ol>
      </section>
      <section>
        <h2>第 10 条（事業グループの設定）</h2>
        <ol class="dot">
          <li>契約者は、当社に対し、事業に関する情報として当社が定める情報を当社の定める方法で当社に提供することにより、本サービス上で利用することのできる事業グループを設定することができるものとします。</li>
          <li>前項に定める事業グループを設定した場合、契約者は、かかる設定の完了時に、事業グループ毎に本サービスの利用ができるようになります。</li>
        </ol>
      </section>
      <section>
        <h2>第 11 条（事業グループの主体）</h2>
        <ol class="dot">
          <li>契約者は、当社の定める条件及び方法に従い、ユーザーを事業グループへ招待すること、又は当該ユーザーを事業グループから削除することができるものとします。</li>
          <li>前項の規定に基づき事業グループに招待されたユーザーは、当該事業グループのデータ等の作成、編集及び閲覧その他当社が定める権限の全部又は一部を行使することができるものとします。</li>
          <li>契約者は、事業グループに招待したユーザーが本規約に従い本サービスを利用するよう管理・監督することとします。当該ユーザーが本規約の内容に違反した場合には、契約者が本規約に違反したものとみなします。ユーザーが本規約の内容のいずれかに違反した場合、当社は、当該ユーザーに対し、契約者を通じることなく、本規約に定める措置を行使することができるものとします。</li>
          <li>契約者又はユーザーが本規約のいずれかに違反したことに起因して、第三者に損害が生じた場合又は第三者と紛争が生じた場合、当社は何ら責任を負うことはなく、契約者が自らの責任と費用負担によりこれを解決するものとします。かかる紛争によって当社が損害（弁護士費用を含みますが、これに限りません。）を被った場合、契約者は当社が被った一切の損害を賠償するものとします。</li>
        </ol>
      </section>
      <section>
        <h2>第 12 条（禁止事項）</h2>
        <p>契約者は、本サービスの利用にあたり、以下の各号のいずれかに該当する行為をしてはなりません。</p>
        <ol class="parenthesis">
          <li>法令に違反する行為、法令違反を助長する行為又はそれらのおそれのある行為</li>
          <li>当社、本サービスの他の契約者又はその他第三者に対する詐欺又は脅迫行為</li>
          <li>公序良俗に反する行為</li>
          <li>当社、又は本サービスの他の契約者その他の第三者の知的財産権、肖像権、プライバシーの権利、名誉、その他の権利又は利益を侵害する行為</li>
          <li>
            本サービスを通じ、以下に該当し、又は該当すると当社が判断する情報を送信する行為
            <ol class="katakana">
              <li>過度に暴力的又は残虐な表現を含む情報</li>
              <li>コンピューター・ウィルスその他の有害なプログラムを含む情報</li>
              <li>当社、本サービスの他の契約者又はその他の第三者の名誉又は信用を毀損する表現を含む情報</li>
              <li>過度にわいせつな表現を含む情報</li>
              <li>差別を助長する表現を含む情報</li>
              <li>自殺、自傷行為を助長する表現を含む情報</li>
              <li>薬物の不適切な利用を助長する表現を含む情報</li>
              <li>反社会的な表現を含む情報</li>
              <li>他人に不快感を与える表現を含む情報</li>
              <li>虚偽の内容を含む情報</li>
              <li>宣伝、広告、勧誘、又は営業行為を含む情報</li>
            </ol>
          </li>
          <li>本サービス並びに本サービスを通じてアクセスするコンテンツサイト及び情報提供元のネットワーク又はシステム等に過度な負荷をかける行為</li>
          <li>本サービスの他の契約者の情報の収集を目的とする行為</li>
          <li>本サービスに接続しているシステム全般について、権限なく不正にアクセスする行為、当社の設備に蓄積された情報を不正に書換え若しくは消去する行為、その他当社に損害を与える行為</li>
          <li>他の契約者又は第三者に成りすます行為</li>
          <li>本サービスの他の契約者又はユーザーのユーザーID又はユーザーパスワードを利用する行為（複数人が１つのユーザーID又はユーザーパスワードを共同利用する行為も含まれますが、これに限りません。）</li>
          <li>反社会的勢力等（暴力団、暴力団員、暴力団員でなくなったときから5年を経過しない者、右翼団体、暴力団準構成員、総会屋、社会運動等標榜ゴロ、特殊知能暴力集団その他これに準ずる者を意味します。以下同じ。）への利益供与行為</li>
          <li>暴力又は脅迫的な言動（自己又は関係者が反社会的勢力等である旨を伝える行為も含まれますが、これに限りません。）を用いる行為</li>
          <li>当社による本サービスの運営を妨害するおそれのある行為</li>
          <li>本規約及び本サービスの趣旨・目的に反する行為</li>
          <li>前各号の行為を直接又は間接に惹起し、又は容易にする行為</li>
          <li>その他、当社が不適切と判断する行為</li>
        </ol>
      </section>
      <section>
        <h2>第 13 条（契約者の退会）</h2>
        <ol class="dot">
          <li>契約者は、所定の方法により本サービスを退会することができるものとします。本サービスを退会した契約者及び当該契約者により本サービス利用を認められたユーザーは、契約者の退会の時点から本サービス（当該契約者が退会した本規約に基づくサービス利用契約の範囲に限ります。）を利用することができなくなるものとします。</li>
          <li>退会にあたり、当社に対して負っている債務（本規約上の債務のみならず、契約者の当社に対する損害賠償債務も含みますが、これらに限りません。）がある場合は、契約者は、当社に対して負っている債務の一切について当然に期限の利益を失い、直ちに当社に対して全ての債務を履行しなければなりません。</li>
          <li>契約者が本規約に基づくサービス利用契約の途中で退会した場合でも、当該契約の残期間に対応する利用料金は発生するものとし、当社は、契約者に対し、日割計算等による精算及び返金は行いません。</li>
          <li>契約者は、本サービス退会後も、当社に対する本サービスの利用により契約者が負った義務及び債務を免れるものではありません。</li>
          <li>当社は、契約者が本サービスの全部又は一部を退会した後も、退会した本サービスに係る当該契約者及び当該契約者が第12条第１項の規定に基づき追加したユーザーのユーザーアカウント及び当該契約者が当社に提供したデータ等その他一切の情報を保有、利用又は削除することができるものとします。</li>
          <li>本サービス退会後、契約者が再度本サービスの登録を希望する際は、再度登録手続を行う必要があります。契約者は再度の登録手続によっても、退会前のデータが引き継がれないことを予め承諾するものとします。</li>
          <li>本サービス退会後、当社は、契約者が当社に対し提供した契約者のデータ等その他一切の情報を引渡さないものとし、契約者はこれを異議なく承諾するものとします。</li>
        </ol>
      </section>
      <section>
        <h2>第 14 条（サービス利用停止又はアカウント削除）</h2>
        <ol class="dot">
          <li>
            当社は、契約者が次のいずれかに該当すること、又は該当するおそれがあると当社が判断した場合、事前の通知又は催告することなく、当該契約者の本サービスの利用を一時的に停止その他の必要な措置をし、契約者としての登録を抹消、又は契約者との間のサービス利用契約を解除することができるものとします。
            <ol class="parenthesis">
              <li>本規約のいずれかの条項に違反した場合又は本規約に違反するおそれがあると当社が判断した場合</li>
              <li>当社に提供された情報の全部又は一部に虚偽の事実があることが判明した場合</li>
              <li>ユーザーID若しくはユーザーパスワード又はコンテンツID若しくはコンテンツパスワードが盗まれたり、第三者に使用されていることが判明した場合</li>
              <li>本サービスの利用料金の決済方法として契約者が指定する銀行口座又はクレジットカードが利用停止若しくは無効扱いとされた場合、又はそれらの不正使用が判明した場合</li>
              <li>本サービスの利用料金の支払いを遅滞し、当社が指定した日までに当該遅滞を解消しない場合</li>
              <li>未成年者、成年被後見人、被保佐人又は被補助人のいずれかであって、法定代理人、後見人、保佐人又は補助人の同意等を得ていないことが判明した場合</li>
              <li>支払停止若しくは支払不能となり、又は破産手続開始、民事再生手続開始、会社更生手続開始、特別清算開始若しくはこれらに類する手続の開始の申立てがあった場合</li>
              <li>信用力の著しい低下又は信用力に影響を及ぼす営業上の重要な変更がなされた場合</li>
              <li>契約者が解散を決議し又は他の会社との合併等の会社再編を決議した場合</li>
              <li>契約者が死亡した場合又は後見開始、保佐開始若しくは補助開始の審判を受けた場合であってサービス利用契約を解除する合理的な理由があるとき</li>
              <li>当社からの問合せその他の回答を求める連絡に対して30日間以上応答がない場合</li>
              <li>反社会的勢力等である、又は資金提供その他を通じて反社会的勢力等の維持、運営若しくは経営に協力若しくは関与する等反社会的勢力等との何らかの交流若しくは関与を行っていると当社が判断した場合、又は反社会的勢力等が経営に実質的に関与している法人等であると判明した場合</li>
              <li>過去に本サービス又は当社が提供する他のサービスの利用停止、ユーザーID削除等の措置を受け又は現在受けている場合</li>
              <li>本サービスの運営・保守管理上必要であると当社が判断した場合</li>
              <li>その他前各号に類する事由があると当社が判断した場合</li>
            </ol>
          </li>
          <li>前項各号のいずれかの事由に該当した場合、契約者は、当社に対して負っている債務の一切（本規約上の債務のみならず、契約者の当社に対する損害賠償債務も含みますが、これらに限りません。）について当然に期限の利益を失い、直ちに当社に対して全ての債務を履行しなければなりません。</li>
          <li>契約者は、第 1 項に基づく措置がなされた後も、当社及びその他の第三者に対する本サービス利用上の一切の義務及び債務（損害賠償を含みますが、これに限りません。）を免れるものではありません。</li>
          <li>当社は、本条に基づき当社が行った行為により契約者に生じた損害について一切の責任を負わず、第 1 項に基づく措置がなされた後も、当該契約者が当社に提供したデータ等その他一切の情報を保有・利用することができるものとします。</li>
        </ol>
      </section>
      <section>
        <h2>第 15 条（本サービスの変更、中断、中止、追加及び廃止等）</h2>
        <ol class="dot">
          <li>当社は、契約者に事前の通知をすることなく、本サービス及び本サービスに関するソフトウェアの内容の全部又は一部を変更、追加、廃止することができるものとします。</li>
          <li>当社は、当社の判断により本サービスの全部又は一部の提供・運営を中止することができるものとします。また、当社は、当社の判断により本サービスの全部又は一部の提供・運営を中止する場合、当社が適当と判断する方法で契約者にその旨通知します。ただし、緊急の場合は契約者への通知を行わない場合があります。</li>
          <li>
            当社は、以下各号の事由が生じた場合には、契約者に事前に通知することなく、本サービスの全部又は一部を一時的に中断することができるものとします。
            <ol class="parenthesis">
              <li>本サービス用のハード・ソフト・通信機器設備等に関わるメンテナンスや修理を定期的又は緊急に行う場合</li>
              <li>電気通信事業者の役務が提供されない場合</li>
              <li>天災等の不可抗力により本サービスの提供が困難な場合</li>
              <li>火災、停電、その他の不慮の事故、戦争、紛争、動乱、暴動又は労働争議等により本サービスの提供が困難な場合</li>
              <li>アクセス過多、その他予期せぬ要因でシステムに負荷が集中した場合</li>
              <li>契約者のセキュリティを確保する必要が生じた場合</li>
              <li>コンテンツサイト、情報提供元のシステム又は本サービスと連携する第三者が運営するサービス（以下「第三者サービス」といいます。）等の全部又は一部の提供が一時的に停止又は中断された場合</li>
              <li>法令又はこれらに基づく措置により本サービスの運営が不能となった場合</li>
              <li>その他前各号に準じ当社が必要と判断した場合</li>
            </ol>
          </li>
          <li>
            契約者は、次の各号のいずれかに該当する場合、本サービスの利用の全部又は一部が制限されることがあることに予め承諾します。
            <ol class="parenthesis">
              <li>本サービスの利用資格等の確認を目的としたユーザーID、ユーザーパスワード等の認証機能において、利用資格等の確認ができない場合</li>
              <li>インターネットに接続できない環境において、本サービスを利用する場合</li>
              <li>リアルタイム通信ができない通信状況において本サービスを利用する場合</li>
            </ol>
          </li>
          <li>当社は、契約者に対し、本サービスに関するソフトウェアのサポート及び修正版（アップデート版を含みます。）の提供を行う義務を負いません。</li>
          <li>当社は、本条に基づき当社が行った措置により契約者に生じた損害について一切の責任を負いません。</li>
        </ol>
      </section>
      <section>
        <h2>第 16 条（権利の帰属）</h2>
        <ol class="dot">
          <li>本サービスにおいて、当社が提供する情報等に関する一切の知的財産権は当社又は当社にライセンスを許諾している者に帰属します。</li>
          <li>契約者は、当社の許諾を得ずに、当社が提供する情報等の翻訳、編集及び改変等を行い、又は第三者に使用させたり公開することはできず、いかなる理由によっても当社又は当社にライセンスを許諾している者の知的財産権を侵害するおそれのある行為（逆アセンブル、逆コンパイル、リバースエンジニアリングを含みますが、これらに限りません。）をしてはなりません。</li>
          <li>本サービス上には商標、ロゴ及びサービスマーク等（以下総称して「商標等」といいます。）が表示される場合がありますが、当社は、契約者その他の第三者に対し何ら当該商標等を譲渡し、又は使用を許諾するものではありません。</li>
          <li>契約者は、当社に対し、登録情報（個人番号等を除きます。本項において同じです。）及び送信データ（個人番号等を除きます。本項及び次項において同じです。）を送信することについての適法な権利を有していること、並びに登録情報、送信データ及びその送信が第三者の権利を侵害していないことについて、当社に対し表明し、保証するものとします。</li>
          <li>契約者は、データ等について、当社に対し、契約者に本サービスを提供するために必要な範囲、又は本規約に基づく場合に限り、世界的、非独占的、無償、サブライセンス可能かつ譲渡可能な使用、複製、配布、派生著作物の作成、表示及び実行に関するライセンスを付与します。</li>
          <li>契約者は、当社及び当社から権利を承継し又は許諾された者に対して著作者人格権を行使しないことに同意するものとします。</li>
        </ol>
      </section>
      <section>
        <h2>第 17 条（登録情報等の管理）</h2>
        <p>契約者は登録情報を厳重に管理・保管するものとします。契約者による登録情報の失念、消失及び登録情報の管理の不徹底による損害の責任は契約者が負うものとし、当社は一切の責任を負いません。また契約者は自己の登録情報が他者によって不正利用されていることを知った場合、直ちに当社にその旨を連絡し、当社の指示に従うものとします。</p>
      </section>
      <section>
        <h2>第 18 条（情報の利用等）</h2>
        <ol class="dot">
          <li>
            当社は、本サービスの利用を通じて取得したデータ等その他一切の情報を、法令で定められている範囲を超えて、また、次の各号に掲げる利用目的の範囲を超えて利用することはありません。ただし、契約者の情報のうち、個人情報（個人番号等を除きます。）については第6項に従うものとします。
            <ol class="parenthesis">
              <li>契約者の同一性確認のため</li>
              <li>本サービスを提供するため</li>
              <li>利用料金、遅延損害金等の請求のため</li>
              <li>代金決済等におけるクレジットカードの有効性の確認のため</li>
              <li>契約者に合わせた本サービス上の情報、広告配信のカスタマイズのため</li>
              <li>本サービス及び当社又は当社グループ会社が提供するサービスに関する案内のため</li>
              <li>本サービスの停止・中止・契約解除の通知のため</li>
              <li>本規約に違反する行為への対応のため</li>
              <li>本サービスに関する当社の規約、ポリシー等の変更などの通知のため</li>
              <li>紛争、訴訟などへの対応のため</li>
              <li>本サービスに関する問い合わせ等への対応のため</li>
              <li>年代、所属、業種、規模その他の観点から分析することにより、個別の法人、団体及び個人を識別することのできない形式に加工した匿名加工データ及び統計データ（以下「統計データ等」といいます。）を作成し、本サービスの向上及び改善、サービス開発、研究、市場分析、マーケティングを行うため</li>
              <li>前各号に付随する本サービスの提供・維持・改善・開発のため</li>
            </ol>
          </li>
          <li>
            当社は、契約者の承諾がない限り、当該契約者の情報を第三者に開示又は共有することはありません。ただし、次の各号に掲げる場合は除きます。
            <ol class="parenthesis">
              <li>契約者が希望する顧客に対し請求書の配信、郵送をする場合（開示又は共有する情報は、請求書の記載事項その他必要な情報に限ります。)</li>
              <li>当社が本サービス利用状況を把握するために適切と判断するツールを利用する上で必要な範囲内において当該ツール提供元に契約者の情報を提供する場合</li>
              <li>本サービスと第三者サービス等との連携及びその機能向上に合理的な範囲内において当該第三者サービス等の提供元に契約者の情報を提供する場合</li>
              <li>当社が利用目的の達成に必要な範囲内において契約者の情報の取扱いの全部又は一部を委託する場合</li>
              <li>契約者に利用料金を請求する目的で、決済システム会社、クレジットカード会社及び銀行に本サービスを利用している契約者の情報を預託する場合</li>
              <li>合併その他の事由による事業の承継に伴って契約者の情報が提供される場合</li>
              <li>法令に基づく場合</li>
              <li>法令諸規則に基づき裁判所、警察等の公的機関に開示を求められた場合</li>
            </ol>
          </li>
          <li>当社は、情報の利用目的を、変更前の利用目的と相当の関連性を有すると合理的に認められる範囲内において変更することがあり、変更した場合には契約者に通知又は公表します。</li>
          <li>本サービスでは、本サービス利用状況を把握するため、Google Analytics（詳細はこちらをご確認ください。）、その他当社が適切と判断するツールを利用して、アクセスログ等を収集しています。これらのツールでは、cookie（クッキー）および Google社が提供するAdvertising ID（AAID）等（以下「クッキー等」といいます。）等を使用し、個人を特定する情報を含むことなく、本サービスの利用状況を収集されることがあります。収集された情報は各ツール提供元の個人情報保護方針に基づき管理されています。各ツール提供元の個人情報保護方針については、各ツール提供元のサイトをご覧ください。当社は、各ツール提供元のサービス利用による損害について責任を負わないものとします。</li>
          <li>当社は、統計データ等を、第三者に開示することがあります。この場合、開示されるのは特定の法人、団体及び個人を識別することのできない統計データ等のみであり、契約者自身を識別できる情報を開示することはありません。</li>
          <li>当社は、本サービスの利用を通じて取得した契約者の個人情報を当社が別途規定する個人情報保護方針（「個人情報の取扱について」を含みます。以下同じ。）に則って、管理するものとし、契約者は、当社が契約者のデータ等に含まれる個人情報を個人情報保護方針に従って取り扱うことに予め承諾するものとします。</li>
        </ol>
      </section>

      <section>
        <h2>第 19 条（反社会的勢力の排除）</h2>
        <p>
          当社は、反社会的勢力等による本サービスの利用を禁止します。当社は、契約者がこれらの者に該当すると判断した場合、事前に契約者に通知することなく、本サービスの提供を停止し、又はサービス利用契約の解除をすることができるものとします。当社は、本サービスの提供停止又はサービス利用契約の解除によって契約者に生じた損害や不利益について、一切の責任を負いません。
        </p>
      </section>

      <section>
        <h2>第 20 条（損害賠償）</h2>
        <ol class="dot">
          <li>契約者は、本規約に違反することにより、又は本サービスの利用に関連して当社に損害を与えた場合、当社に対しその全ての損害（弁護士等専門家費用及び当社人件費相当額を含みます。）を賠償しなければなりません。</li>
          <li>契約者による本サービスの利用に関連して、当社が、他の契約者又はユーザーその他の第三者から権利侵害その他の理由により何らかの請求を受けた場合、当該契約者は、当該請求に基づき当社が当該第三者に支払いを余儀なくされた金額及び当該請求に係る紛争等の解決のために当社が負担した金額（弁護士等専門家費用及び当社人件費相当額を含みます。）を賠償しなければなりません。</li>
        </ol>
      </section>

      <section>
        <h2>第 21 条（保証の否認及び免責）</h2>
        <ol class="dot">
          <li>当社は、本サービス、本サービスを通じて提供されるコンテンツその他本サービスにより契約者が取得し得る一切の情報が、契約者の特定の目的に適合すること、期待する機能・商品的価値・正確性・有用性を有すること、契約者による本サービスの利用が契約者に適用のある法令又は業界団体の内部規則等に適合すること、不具合が生じないこと、本サービスの利用に関する問題を解決すること、本サービスを通じて提供されるコンテンツが適法に利用可能であること、当社以外が提供するサービス等の利用規約等を遵守していること及び第三者の権利を侵害しないこと等について、何ら保証するものではありません。</li>
          <li>当社は、本サービスについて食品表示診断士の業務を提供するものではなく、契約者は、本サービスを通じて取得した情報等について、自らの責任において必要に応じて変更、修正したうえで利用するものとします。契約者は、食品表示の際には記載内容に誤りがないか、必ず消費者庁、消費者庁が発行する各種資料、食品表示診断士等により内容を確認し、表示内容の正誤について、当社は一切の責任を負わないことを予め承諾するものとします。</li>
          <li>当社は、当社による本サービスの提供の中断、停止、終了、利用不能又は変更、契約者が本サービスに送信したデータ等の削除又は消失、契約者の登録の抹消、本サービスの利用によるデータ等の消失又は機器の故障若しくは損傷、その他本サービスに関して契約者が被った損害につき、賠償する責任を一切負わないものとします。</li>
          <li>当社は、契約者間の通信や活動に関与しません。万一契約者間で紛争や問題が生じた場合には、当該契約者間の責任と費用でこれを解決するものとし、当社はこれに一切関与しません。</li>
          <li>契約者と第三者との間で紛争が生じた場合には、契約者は自身の責任と費用でこれを解決するものとし、当社はこれに一切関与しません。</li>
          <li>当社ウェブサイト等から他のウェブサイトへのリンク又は他のウェブサイトから当社ウェブサイト等へのリンクが提供されている場合でも、当社は、当社ウェブサイト等以外のウェブサイト及びそこから得られる情報に関して一切の責任を負わないものとします。</li>
          <li>当社は、本サービスが全ての端末に対応していることを保証するものではなく、また、仮に本サービスの利用開始時に対応していた場合でも、本サービスの利用に供する端末のOSのバージョンアップ等に伴い本サービスの動作に不具合が生じる可能性があることについて、契約者は予め承諾するものとします。当社は、かかる不具合が生じた場合に当社が行うプログラムの修正等により当該不具合が解消されることを保証するものではありません。</li>
          <li>当社は、本サービスに関連して契約者が被った損害について、当社に故意又は重過失があったときを除き、一切賠償の責任を負いません。なお、当社に故意又は重過失があった場合、及び消費者契約法の適用その他の理由により、本項その他当社の損害賠償責任を免責する規定にかかわらず当社が契約者に対して損害賠償責任を負う場合においても、当社の賠償責任の範囲は、当社の責に帰すべき事由により現実に発生した直接かつ通常の損害に限られるものとし、かつ、損害の事由が生じた時点から遡って過去1年間に当該契約者から現実に受領した本サービスの利用料金の総額を上限とします。</li>
        </ol>
      </section>

      <section>
        <h2>第 22 条（サービス利用契約の有効期間）</h2>
        <p>
          サービス利用契約は、本サービスの提供期間中、契約者について第 4 条に基づく利用登録が完了した日から当該契約者が本サービスを退会した日、又は当該契約者のアカウントが削除された日のいずれか早い日までの間、当社と契約者との間で有効に存続するものとします。
        </p>
      </section>

      <section>
        <h2>第 23 条（規約改定）</h2>
        <p>
          当社は、本規約を自己の裁量により変更できるものとします。当社は、本規約を変更した場合には、契約者に対し、当社が適当と認める方法により当該変更内容を通知するものとし、当該変更内容の通知後、契約者が本サービスを利用した場合又は当社の定める期間内に退会の手続をとらなかった場合には、契約者は、本規約の変更に同意したものとみなします。
        </p>
      </section>

      <section>
        <h2>第 24 条（連絡・通知）</h2>
        <p>
          本サービスに関する問い合わせその他契約者から当社に対する連絡又は通知、及び本規約の変更に関する通知その他当社から契約者に対する連絡又は通知は、当社の定める方法で行うものとします。なお、当社が、契約者に対する連絡又は通知を電子メール送信又は当社ウェブサイト等での掲載により行う場合、当該電子メールが当社から発信された時点又は当社ウェブサイト等に掲載された時点で、当該連絡又は通知が行われたものとみなします。
        </p>
      </section>

      <section>
        <h2>第 25 条（本規約上の地位の譲渡等）</h2>
        <ol class="dot">
          <li>契約者は、当社の書面による事前の承諾なく、サービス利用契約上の地位又は本規約に基づく権利義務の全部又は一部を、第三者に対する譲渡、承継（合併、会社分割等による包括承継を含みます。）し又は担保の目的に供することはできません。</li>
          <li>当社が本サービスにかかる事業を他者に譲渡した場合には、当該事業譲渡に伴いサービス利用契約上の地位、本規約に基づく権利及び義務並びに契約者の登録情報その他の顧客情報を当該事業譲渡の譲受人に譲渡できるものとし、契約者は、かかる譲渡につき予め同意したものとみなします。</li>
        </ol>
      </section>

      <section>
        <h2>第 26 条（分離可能性）</h2>
        <p>
          本規約のいずれかの条項又はその一部が、消費者契約法その他の法令等により無効又は執行不能と判断された場合であっても、本規約の残りの規定及び一部が無効又は執行不能と判断された規定の残りの部分は、継続して完全に効力を有します。当社及び契約者は、当該無効若しくは執行不能の条項又は部分を適法とし、執行力を持たせるために本規約を必要な範囲で修正し、当該無効若しくは執行不能な条項又は部分の趣旨並びに法律的及び経済的に同等の効果を確保できるように努めるものとします。
        </p>
      </section>

      <section>
        <h2>第 27 条（存続条項）</h2>
        <p>
          第 13 条（契約者の退会）第 2 項から第 7 項、第 14 条（サービス利用停止又はアカウント削除）第 3 項及び第 4 項、第 16 条（権利の帰属）、第 17 条（登録情報等の管理）、第 18 条（情報の利用等）、第 19 条（反社会的勢力の排除）から第 21 条（保証の否認及び免責）、並びに第 25 条（本規約上の地位の譲渡等）から第 29 条（協議解決）については、当社と契約者との間のサービス利用契約が終了した場合でも、その終了原因の如何を問わず、なお効力を有するものとします。
        </p>
      </section>

      <section>
        <h2>第 28 条（準拠法及び合意管轄）</h2>
        <p>
          本規約の準拠法は日本法とし、本規約に起因し又は関連する一切の紛争については、京都地方裁判所を第一審の専属的合意管轄裁判所とします。
        </p>
      </section>

      <section>
        <h2>第 29 条（協議解決）</h2>
        <p>
          当社及び契約者は、本規約に定めのない事項又は本規約の解釈に疑義が生じた場合には、互いに信義誠実の原則に従って協議の上速やかに解決を図るものとします。
        </p>
      </section>

      <section>
        2019年09月12日 制定<br>
        2022年04月06日 改定<br/>
        2024年08月01日 改定
      </section>
    </main>
    <footer-component></footer-component>
  </div>
</template>

<script>
  import FooterComponent from "../components/FooterComponent";
  import {dispatchInitialized} from "@/libs/cypress";
  export default {
    components: {
      FooterComponent
    },
    mounted() {
      dispatchInitialized();
    }
  }
</script>

<style lang="scss" scoped>
  @import 'objects/pages/_page-terms.scss';
</style>
