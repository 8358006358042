<template>
  <div>
    <main>
      <h1>スマート食品表示代行プライバシーポリシー</h1>
      <p>
        株式会社Modelor及び株式会社スマショク（以下「当社」といいます。）は、個人情報保護の重要性について認識し、個人情報の保護に関する法律（以下「個人情報保護法」といいます。）を遵守すると共に、以下のプライバシーポリシー（以下「本プライバシーポリシー」といいます。）に従い、適切な取扱い及び保護に努めます。<br>
        <br>
        なお、本プライバシーポリシーにおいて別段の定めがない限り、本プライバシーポリシーにおける用語の定義は、個人情報保護法の定めに従います。
      </p>
      <section>
        <h2>１. 個人情報の定義</h2>
        <p>本プライバシーポリシーにおいて、個人情報とは、個人情報保護法第 2 条第 1 項により定義される個人情報を意味するものとします。</p>
      </section>
      <section>
        <h2>２. 個人情報の利用目的</h2>
        <p>当社は、個人情報を以下の目的で使用します。</p>
        <ol class="dot">
          <li>当社が提供する「スマート食品表示代行」という名称の規格書に関する情報提供等のサービス（理由の如何を問わずサービスの名称又は内容が変更された場合は、当該変更後のサービスを含みます。）及びその他の当社が提供するサービス（以下「当社サービス」といいます。）の提供のため</li>
          <li>当社サービスに関するご案内、お問い合わせ等への対応のため</li>
          <li>当社の商品、サービス等のご案内のため</li>
          <li>当社サービスに関する当社の規約、ポリシー等（以下「規約等」といいます。）に違反する行為に対する対応のため</li>
          <li>当社サービスに関する規約等の変更などを通知するため</li>
          <li>当社サービスの改善、新サービスの開発等に役立てるため</li>
          <li>当社のサービスに関連して、個人を識別できない形式に加工した統計データを作成するため</li>
          <li>その他、上記利用目的に付随する目的のため</li>
        </ol>
      </section>
      <section>
        <h2>３. 個人情報利用目的の変更</h2>
        <p>当社は、個人情報の利用目的について関連性を有すると合理的に認められる範囲において変更することがあり、変更した場合には個人情報の主体である個人（以下「本人」といいます。）に通知し又は公表します。</p>
      </section>
      <section>
        <h2>４. 個人情報利用の制限</h2>
        <p>当社は、個人情報保護その他の法令により許容される場合を除き、本人の同意を得ず、利用目的の達成に必要な範囲を超えて個人情報を取り扱いません。但し、次の場合はこの限りではありません。</p>
        <ol class="dot">
          <li>法令に基づく場合</li>
          <li>人の生命、身体又は財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき</li>
          <li>公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき</li>
          <li>国の機関もしくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に影響を及ぼすおそれがあるとき</li>
        </ol>
      </section>
      <section>
        <h2>５. 個人情報の適正な取得</h2>
        <ol class="dot">
          <li>当社は、適正に個人情報を取得し、偽りその他不正の手段により取得しません。</li>
          <li>
            当社は、次の場合を除き、あらかじめ本人の同意を得ないで、要配慮個人情報（個人情報保護法 2 条第 3 項に定義されるものを意味します。）を取得しません。
            <ol class="parenthesis">
              <li>第 4 項各号のいずれかに該当する場合</li>
              <li>当該要配慮個人情報が、本人、国の機関、地方公共団体、個人情報保護法第 76 条第 1 項各号に掲げる者その他個人情報保護委員会規則で定める者により公開されている場合</li>
              <li>本人を目視し、又は撮影することにより、その外見上明らかな要配慮個人情報を取得する場合</li>
              <li>第三者から要配慮個人情報の提供を受ける場合であって、当該第三者による当該提供が第 7 条第 1 項各号のいずれかに該当するとき</li>
            </ol>
          </li>
          <li>
            当社は、第三者から個人情報の提供を受けるに際しては、個人情報保護委員会規則で定めるところにより、次に掲げる事項の確認を行います。ただし、当該第三者による当該個人情報の提供が第 4 項各号のいずれかに該当する場合又は第 7 条第 1 項各号のいずれかに該当する場合を除きます。
            <ol class="parenthesis">
              <li>当該第三者の氏名又は名称及び住所、並びに法人の場合はその代表者（法人でない団体で代表者又は管理人の定めのあるものの場合は、その代表者又は管理人）の氏名</li>
              <li>当該第三者による当該個人情報の取得の経緯</li>
            </ol>
          </li>
        </ol>
      </section>
      <section>
        <h2>６. 個人情報の安全管理</h2>
        <p>当社は、個人情報の紛失、破壊、改ざん及び漏洩などのリスクに対して、個人情報の安全管理が図られるよう、当社の従業員に対し、必要かつ適切な監督を行います。また、当社は、個人情報の取扱いの全部又は一部を委託する場合は、委託先において個人情報の安全管理が図られるよう、必要かつ適切な監督を行います。</p>
      </section>
      <section>
        <h2>７. 第三者提供</h2>
        <ol class="dot">
          <li>
            当社は、第 4 条各号のいずれかに該当する場合を除くほか、あらかじめ本人の同意を得ないで、個人情報を第三者に提供しません。但し、次に掲げる場合は上記に定める第三者への提供には該当しません。
            <ol class="parenthesis">
              <li>利用目的の達成に必要な範囲内において個人情報の取扱いの全部又は一部を委託することに伴って個人情報を提供する場合</li>
              <li>合併その他の事由による事業の承継に伴って個人情報が提供される場合</li>
              <li>個人情報保護法の定めに基づき共同利用する場合</li>
            </ol>
          </li>
          <li>第 7 条第 1 項の定めにかかわらず、当社は、第 4 条各号のいずれかに該当する場合を除くほか、外国（個人情報保護法第 24 条に基づき個人情報保護委員会規則で指定される基準に適合する体制を整備している者を除きます。）に個人情報を提供する場合には、あらかじめ外国にある第三者への提供を認める旨の本人を得るものとします。</li>
          <li>当社は、個人情報を第三者に提供したときは、個人情報保護法第 25 条に従い、記録の作成及び保存を行います。</li>
          <li>当社は、第三者から個人情報の提供を受けるに際しては、個人情報保護法第 26 条に従い、必要な確認を行い、当該確認にかかる記録の作成及び保存を行うものとします。</li>
        </ol>
      </section>
      <section>
        <h2>８. 個人情報の開示</h2>
        <p>当社は、本人から、個人情報保護法の定めに基づき個人情報を求められたときは、本人ご自身からのご請求であることを確認の上で、本人に対し、遅滞なく開示を行います（当該個人情報が存在しないときにはその旨を通知いたします。）。但し、個人情報保護法その他の法令により、当社が開示の義務を負わない場合は、この限りではありません。なお、個人情報の開示につきましては、手数料（ 1 件あたり 1,500 円 ）を頂戴しておりますので、あらかじめご了承ください。</p>
      </section>
      <section>
        <h2>９. 個人情報の訂正等</h2>
        <p>当社は、本人から、個人情報が真実でないという理由によって、個人情報保護法の定めに基づきその内容の訂正、追加又は削除（以下「訂正等」といいます。）を求められた場合には、本人ご自身からのご請求であることを確認の上で、利用目的の達成に必要な範囲内において、遅滞なく必要な調査を行い、その結果に基づき、個人情報の内容の訂正等を行い、その旨を本人に通知します（訂正等を行わない旨の決定をしたときは、本人に対しその旨を通知いたします。）。但し、個人情報保護法その他の法令により、当社が訂正等の義務を負わない場合は、この限りではありません。</p>
      </section>
      <section>
        <h2>１０. 個人情報の利用停止等</h2>
        <p>
          当社は、本人から、本人の個人情報が、あらかじめ公表された利用目的の範囲を超えて取り扱われているという理由又は偽りその他不正の手段により取得されたものであるという理由により、個人情報保護法の定めに基づきその利用の停止又は消去（以下「利用停止等」といいます。）を求められた場合、又は個人情報がご本人の同意なく第三者に提供されているという理由により、個人情報保護法の定めに基づきその提供の停止（以下「提供停止」といいます。）を求められた場合において、そのご請求に理由があることが判明した場合には、本人ご自身からのご請求であることを確認の上で、遅滞なく個人情報の利用停止等又は提供停止を行い、その旨を本人に通知します。但し、個人情報保護法その他の法令により、当社が利用停止等又は提供停止の義務を負わない場合は、この限りではありません。
        </p>
      </section>
      <section>
        <h2>１１. 匿名加工情報の取扱い</h2>
        <ol class="dot">
          <li>当社は、匿名加工情報（個人情報保護法第 2 条第 9 項に定めるものを意味し、同法第 2 条第 10 項に定める匿名加工情報データベース等を構成するものに限ります。以下同じ。）を作成するときは、個人情報保護委員会規則で定める基準に従い、個人情報を加工するものとします。</li>
          <li>当社は、匿名加工情報を作成したときは、個人情報保護委員会規則で定める基準に従い、安全管理のための措置を講じます。</li>
          <li>当社は、匿名加工情報を作成したときは、個人情報保護委員会規則で定めるところにより、当該匿名加工情報に含まれる個人に関する情報の項目を公表します。</li>
          <li>当社は、匿名加工情報（当社が作成したもの及び第三者から提供を受けたものを含みます。以下別段の定めがない限り同様とします。）を第三者に提供するときは、個人情報保護委員会規則で定めるところにより、あらかじめ、第三者に提供される匿名加工情報に含まれる個人に関する情報の項目及びその提供の方法について公表するとともに、当該第三者に対して、当該提供に係る情報が匿名加工情報である旨を明示します。</li>
          <li>当社は、匿名加工情報を取り扱うに当たっては、匿名加工情報の作成に用いられた個人情報に係る本人を識別するために、（1）匿名加工情報を他の情報と照合すること、及び（2）当該個人情報から削除された記述等若しくは個人識別符号又は個人情報保護法第 36 条第 1 項の規定により行われた加工の方法に関する情報を取得すること（（2）は第三者から提供を受けた当該匿名加工情報についてのみ）を行わないものとします。</li>
          <li>当社は、匿名加工情報の安全管理のために必要かつ適切な措置、匿名加工情報の作成その他の取扱いに関する苦情の処理その他の匿名加工情報の適正な取扱いを確保するために必要な措置を自ら講じ、かつ、当該措置の内容を公表するよう務めるものとします。</li>
        </ol>
      </section>
      <section>
        <h2>１２. Coolie（クッキー）その他の技術の利用</h2>
        <p>
          当社のサービスは、Cookie 及びこれに類する技術を利用することがあります。これらの技術は、当社による当社のサービスの利用状況等の把握に役立ち、サービス向上に資するものです。Cookie を無効化されたいユーザーは、ウェブブラウザの設定を変更することにより Cookie を無効化することができます。但し、Cookie を無効化すると、当社のサービスの一部の機能をご利用いただけなくなる場合があります。
        </p>
      </section>
      <section>
        <h2>１３. お問い合わせ</h2>
        <p>
          開示等のお申出、ご意見、ご質問、苦情のお申出その他個人情報の取扱いに関するお問い合わせは、以下の窓口までお願い致します。<br>
          <br>
          〒603-8832 京都府京都市北区大宮南田尻町3番地の1<br>
          株式会社Modelor<br>
          電話番号: 050-3172-1382<br>
          E-mail: support@smashoku.com<br>
          （なお、受付時間は、平日 11 時から 17 時までとさせていただきます。）
        </p>
      </section>
      <section>
        <h2>１４. 継続的改善</h2>
        <p>
          当社は、個人情報の取扱いに関する運用状況を適宜見直し、継続的な改善に務めるものとし、必要に応じて、本プライバシーポリシーを変更することがあります。
        </p>
      </section>
      <section>
        2021年06月09日 制定<br/>
        2024年07月08日 改定<br/>
        2024年08月01日 改定
      </section>
    </main>
    <FooterComponentAgency></FooterComponentAgency>
  </div>
</template>

<script>
import FooterComponentAgency from './FooterComponentAgency.vue';
import {dispatchInitialized} from "@/libs/cypress";

export default {
  components: {
    FooterComponentAgency
  },
  mounted() {
    dispatchInitialized();
  }
}
</script>

<style lang="scss" scoped>
  @import 'objects/pages/_page-terms.scss';
</style>
