






































import { Component, Vue } from 'vue-property-decorator';
import LogoSpec from "@/components/Logo/LogoSpec.vue";

@Component({
  components: {
    LogoSpec
  }
})
export default class FooterComponent extends Vue {
}
